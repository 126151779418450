import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'
import DefaultLayout from "../../components/Layouts/DefaultLayout";
import Breadcrumbs from '../../components/Breadcrumbs';

const CareerDetails = (props) => {
  useEffect(() => {
    window.navigateFrom = 'career';
  }, []);

  let {
    Employment_Status: employmentStatus,
    Experience: experience,
    Job_Location: jobLocation,
    Job_Post: jobPost,
    Published_on: publishedOn,
    Vacancy: vacancy,
    Job_Description: jobDescription,
    Job_Form: jobForm
  } = props.data.airtable.data;

  return (
    <DefaultLayout {...props}>
      <section className="career-details">
        <div>
          <Breadcrumbs menus={[
            {
              name: 'Careers',
              path: "/join-us#career",
              params: {
                state: { navigateFrom: 'career' }
              }
            },
            {
              name: jobPost,
              path: ''
            }
          ]} />
        </div>
        <div className='job-post'>
          {jobPost}
        </div>
        <div className='job-description-root'>
          <div className='job-description'>
            <ReactMarkdown>{jobDescription}</ReactMarkdown>
            <div className='apply-button-root'>
              <a href={jobForm} target="_blank">
                <button className="apply-button">Apply Now</button>
              </a>
            </div>
          </div>
          <div className='job-summary'>
            <div className='sticky-header'>
              <div className='widget-border'>
                <div className="widget-title">
                  <h5>Job Summary</h5>
                </div>
                <div className="widget-content">
                  <ul>
                    <li><span className="name">Published on:</span><span className="value">{moment(publishedOn).format('D MMM YYYY')}</span></li>
                    <li><span className="name">Vacancy:</span><span className="value">{vacancy}</span></li>
                    <li><span className="name">Employment Status:</span><span className="value">{employmentStatus}</span></li>
                    <li><span className="name">Experience:</span><span className="value">{experience}</span></li>
                    <li><span className="name">Job Location:</span><span className="value">{jobLocation}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};

export default CareerDetails;

export const query = graphql`
  query CareerPost($id: String) {
    airtable(id: {eq: $id}) {
      data {
        Employment_Status
        Experience
        Job_Location
        Job_Post
        Published_on
        Vacancy
        Status
        Job_Description
        Short_Description
        Job_Form
      }
      id
    }
  }
`