import React from 'react';
import { Link } from 'gatsby';

const Breadcrumbs = ({ menus }) => {
  return (
    <div className='breadcrumbs'>
      {menus.map(({ name, path, params }, index) => {
        if (path === "") {
          return <span className="current-path">{name}</span>
        }
        return <>
          <Link className='link' to={path} {...params}>{name}</Link>
          {
            index !== menus.length - 1 && <span className="separation">/</span>
          }
        </>
      })}
    </div>
  );
};

export default Breadcrumbs;
